import * as React from "react";
import Image from "./Image";

const Servicii = () => {
  // Automatically import all files matching the pattern
  const images = require.context("../images", false, /brand\d+\.png$/);

  // Load images into an array
  const imageList = images.keys().map((imagePath) => images(imagePath));

  return (
    <section className={"servicii"}>
      <div className={"container"}>
        {imageList.map((src, idx) => (
          <Image src={src} alt={"Brand"} />
        ))}
      </div>
      <div className={"text"}>
        Reparăm congelatoare și combine frigorifice Arctic Ardo, Ariston, Beko,
        Bosch, Electrolux, Indesit, Gorenje, Whirlpool, Zanussi și alte modele.
      </div>
    </section>
  );
};

export default Servicii;
