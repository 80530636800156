import React from "react";

import homeImage from "../images/home_image.jpg";
import homeImageWEBP from "../images/home_image.webp";
import contact from "../images/contact.jpeg";
import contactWEBP from "../images/contact.webp";
import despre from "../images/despre.jpg";
import despreWEBP from "../images/despre.webp";

import Image from "./Image";
import Servicii from "./Servicii";
function Home() {
  return (
    <div className="container">
      <nav className="navbar">
        <div className="name">FrigoBestExpres</div>
        {/* <a href="#servicii" className="item">
          Servicii
        </a> */}
        <a href="#despre" className="item">
          Despre
        </a>
        <a href="#contact" className="item">
          Contact
        </a>
      </nav>

      <header className="hero">
        {/* <img src={home_image} alt="home_image" /> */}
        <Image
          src={homeImageWEBP}
          fallback={homeImage}
          alt="Reparatii frigidere Cluj-Napoca"
        />
        <div className="textContainer">
          <h1>Reparații frigidere Cluj</h1>
          <a href="#contact">
            <button>Contact</button>
          </a>
        </div>
      </header>

      <section id="despre" className="section despre">
        <div className="container">
          <div className="text">
            <p>
              Reparăm <b>congelatoare</b> și <b>combine frigorifice</b>
            </p>
            <p>
              Experiență de peste <b>25 ani</b>
            </p>
            <p>
              Oferim garanție <b>3 ani</b>
            </p>
            <label>
              Deplasare și în alte localități la domiciliul clientului
            </label>
          </div>
          {/* <img src={despre} alt="despre" /> */}
          <Image src={despreWEBP} fallback={despre} alt="Despre noi" />
        </div>
      </section>

      <Servicii />

      <footer id="contact" className="section contact">
        {/* <img src={contact} alt="contact" /> */}
        <Image
          src={contactWEBP}
          fallback={contact}
          alt="Contacteaza-ne acum!"
        />
        <div className="cover" />

        <div className="content">
          <div className="text">
            <h1>Contact</h1>
            <p>
              <b>Cristi Cornici</b>
            </p>
            <p>
              Telefon <b>074 213 1064</b> / <b>0722 921 245</b>
            </p>
          </div>
          <a href="tel:074 213 1064">
            <button>Sună acum</button>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Home;
